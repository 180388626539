import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import styles from "./style.module.css";

export const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site: site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div className={styles.footer}>
          <div className={styles.footerLinkArea}>
            <Link className={styles.footerLink} to={"/"}>
              home
            </Link>
              <Link className={styles.footerLink} to={"/profile/"}>
              about
            </Link>
              <Link className={styles.footerLink} to={"/privacypolicy/"}>
              privacy
            </Link>
          </div>
          <div className={styles.footerInfoArea}>
            <Link className={styles.footerInfoText} to={"/"}>
              Betsumushi
            </Link>
          </div>
        </div>
      )}
    />
  );
};
