import React from "react";
import { Link } from "gatsby";

import ogPic from "src/assets/images/ogp.png";

import styles from "./style.module.css";

type OuterProps = {
  slug: string;
  date: string;
  ogpimage: string;
  title: string;
  excerpt: string;
};

export const DescItem = (props: OuterProps) => {
  const { slug, date, ogpimage, title, excerpt } = props;
  return (
    <div key={slug} className={styles.descItem}>
      <Link className={styles.descItemContent} to={slug}>
        <small className={styles.descItemDate}>{date}</small>
        <div className={styles.descItemImageArea}>
          {ogpimage === "" || ogpimage === null ? (
            <img width={400} className={styles.descItemImage} src={ogPic} />
          ) : (
            <img
              className={styles.descItemImage}
              src={"/asset/ogp/" + ogpimage}
            />
          )}
        </div>
        <h3 className={styles.descItemTitle}>{title}</h3>
        <p
          className={styles.descItemDescription}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      </Link>
    </div>
  );
};
