import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import styles from "./style.module.css";

type OuterProps = {
  isHome?: boolean | false;
};

export const Header: React.FC<OuterProps> = (props: OuterProps) => {
  const { isHome } = props;

  return (
    <StaticQuery
      query={graphql`
        query {
          site: site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          {isHome ? (
            <h1 className={styles.siteTitle}>
              <Link className={styles.siteTitleText} to={"/"}>
                {data.site.siteMetadata.title}
              </Link>
            </h1>
          ) : (
            <div className={styles.siteTitle}>
              <Link className={styles.siteTitleText} to={"/"}>
                {data.site.siteMetadata.title}
              </Link>
            </div>
          )}
        </>
      )}
    />
  );
};
