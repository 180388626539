import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

import styles from "./style.module.css";

type OuterProps = {
  to: string;
  text: string;
  id: string;
};
export const TagLink = (props: OuterProps) => {
  const { to, text, id } = props;
  return (
    <div key={id} className={styles.tagBox}>
      <Link className={styles.tagLink} to={`/tags/${kebabCase(to)}/`}>
        {text}
      </Link>
    </div>
  );
};
