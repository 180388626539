import React from "react";
import PropTypes from "prop-types";

import styles from "./layout.module.css";
import "./globalStyle.css";

const Layout: React.FC = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Layout };
