import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import { TagLink } from "src/components/01_atoms/TagLink";

import styles from "./style.module.css";

export const TagList = () => {
  return (
    <div className={styles.tagArea}>
      <div className={styles.tag}>
        <StaticQuery
          query={graphql`
            query {
              tags: allMarkdownRemark(limit: 200) {
                group(field: frontmatter___tags) {
                  fieldValue
                  totalCount
                }
              }
            }
          `}
          render={({ tags }) =>
            tags.group.map((value, id) => (
              <TagLink
                to={value["fieldValue"]}
                text={value["fieldValue"]}
                id={id}
              />
            ))
          }
        />
      </div>
    </div>
  );
};
