import React from "react";

import profilePic from "src/assets/images/black_icon.png";
import styles from "./style.module.css";

export const Bio = () => {
  return (
    <div className={styles.bio}>
      <img
        src={profilePic}
        alt={`こっし@本の虫エンジニア`}
        className={styles.profileImage}
      />
      <p>
        Written by{" "}
        <a href="https://twitter.com/koshihonmushi" target="_blank" className={styles.bioLinkText}>
          <strong>こっし@本の虫エンジニア</strong>
        </a>
      </p>
    </div>
  );
};
